@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;800&display=swap');
* {
  box-sizing: content-box;
}
html,
body,
body > div {
  /* the react root */
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
}

h2 {
  margin: 0;
  font-size: 16px;
}

section {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.flex {
  display: flex;
}
.flex__column {
  flex-direction: column;
}

.fc {
  max-width: 100%;
  width: 100%;
}

.fc-button,
.fc-button:active,
.fc-button:focus,
.fc-button-active,
table tr th {
  outline: none !important;
  border: none !important;
  background: transparent !important;
}

.demo-app-main {
  flex-grow: 1;
  padding: 3em;
}

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
table {
  background: white;
}

select,
.fc-toolbar-chunk:first-child .fc-button-group,
.fc-toolbar-chunk:last-child .fc-button-group,
.fc-scrollgrid-section-header .fc-col-header,
.fc-today-button {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ef3c4d+0,ac96c7+68,ac96c7+100 */
  background: rgb(239, 60, 77); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef3c4d', endColorstr='#ac96c7',GradientType=0 ); /* IE6-9 */
  background-attachment: fixed;
  border: none;
  border-radius: 4px;
}

.fc-today-button {
  margin-top: 10px !important;
  flex: 1;
}

.fc-daygrid-event,
.fc-timegrid-event i {
  color: black !important;
  font-weight: bold;
  font-size: 12px !important;
}

.fc-daygrid-event b,
.fc-timegrid-event b,
.fc-event-time {
  color: rgb(239, 60, 77);
}

.fc-daygrid-block-event {
  display: none !important;
}
table th {
  color: white;
}

.tool-select {
  color: white !important;
}

.tool-btn button {
  font-size: 10px !important;
}
.select__timezone,
.select__filter_by {
  width: 100%;
  color: white;
  padding: 12px 6px;
  margin-left: 0 !important;
  padding-right: 10px;
}

.select__filter_by option,
.select__timezone option {
  color: black !important;
}

.select__timezone option:selected,
.select__filter_by option:selected {
  color: white !important;
}

.fc-today-button {
  /* display: none !important; */
  border-left: 1px solid white;
}

.fc-event {
  cursor: pointer;
}
.fc-event-main {
  color: black;
}

.fc-popover {
  display: none !important;
}

/* .fc-col-header-cell-cushion {
  opacity: 0;
  transform: scale(0);
  transition: transform .3s cubic(55,0,.1,1);
  will-change: transform;
} */

.isi {
  font-size: 12px;
  margin-top: 10px;
}

.speakers-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.speaker {
  /* color: rgb(239, 60, 77); */
  color: black;
  font-size: 12px;
  margin-bottom: 10px;
  /* width: 45%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.speaker-toggle {
  width: 5%;
  color: rgb(239, 60, 77);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.speaker-info {
  width: 95%;
}

.speaker-title {
  font-weight: bold;
}

.speaker-bio-min {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  position: relative;
  height: 2.4em;

  overflow: hidden;
  text-overflow: ellipsis;
}

.speaker-bio-max {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
}

.scheduled-text {
  margin-top: 5px;
}

.title--black {
  color: black;
  font-weight: bold;
}
.non-bold {
  font-weight: 500 !important;
}
.title--black--underline {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}
.title--purple {
  color: #ac96c7;
  font-weight: bold;
}

.title--red {
  border-bottom: 1px rgb(239, 60, 77) solid;
  font-size: 22px;
  font-weight: bold;
  color: rgb(239, 60, 77);
  padding-bottom: 3px;
  margin-bottom: 3px;
  width: 100%;
}

.references__line {
  margin-bottom: 10px;
}

.border--black {
  border: 1px solid black;
  padding: 10px;
  font-weight: bold;
}
.border--yellow {
  border: 1px solid gold;
  padding: 10px;
}

.table__bullet {
  width: 10px;
  vertical-align: top;
}

.small_margin_bottom {
  margin-bottom: 5px;
}

.footer-section {
  width: 100%;
  margin: 10px auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.footer-logo-text {
  margin-bottom: 15px;
  margin-top: 10px;
}

.footer-links {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  .footer-links {
    text-align: center;
    flex-direction: column;
  }
}
.death {
  display: flex;
  justify-content: space-around;
}
.death div {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.modal__event--active {
  border: 2px solid black;
}
.modal__section {
  padding: 0 10px;
  margin-bottom: 50px;
}
.fc-daygrid-event-dot {
  border-radius: 200% !important;
  /* height: 10px;
  width: 10px; */
  border-color: #b093ce !important;
}
.modal__event {
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  display: flex;
  flex-direction: column;
  background-color: white;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,7db9e8+100&1+0,0+100 */
  /*background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(125, 185, 232, 0) 100%
  ); /* FF3.6-15 */
  /*background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(125, 185, 232, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  /*background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#007db9e8',GradientType=1 ); IE6-9 */
}
.bio {
  text-align: left;
  padding: 10px;
}
.modal__content {
  background: rgb(121, 86, 172);
  box-sizing: border-box;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 11px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.modal__content h1 {
  margin-bottom: 16px;
}
.modal__button {
  padding: 2px 30px;
  border: none;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  background: #ef3c4d;
  color: white;
  border-radius: 50px;
  align-self: center;
  margin-left: 10px;
  margin-top: 10px;
  text-decoration: none;
}
.modal__time {
  color: #ef3c4d;
}
.modal__doctor {
  margin-left: 10px;
}
.modal__doctor,
.modal__time {
  font-weight: bold !important;
}

.fc-toolbar-chunk {
  display: flex;
  flex-wrap: wrap;
}

.fc-toolbar-chunk:nth-child(1) {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  vertical-align: bottom;
}
.fc-today-button {
  color: #ffffff !important;
}
.fc-event-title {
  color: black;
}
.fc-list-table tr th {
  background: rgb(239, 60, 77); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef3c4d', endColorstr='#ac96c7',GradientType=0 ); /* IE6-9 */
  background-attachment: fixed;
  border: none;
}

@media screen and (max-width: 768px) {
  .fc-header-toolbar {
    display: flex;
    flex-direction: column;
  }
  .death {
    flex-direction: column;
  }
  .modal__content {
    width: 100% !important;
    transform: none !important;
    display: flex;
    flex-direction: column;
  }

  .modal__event {
    padding: 20px;
    margin: 0 10px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal__button {
    margin: 20px auto;
  }
  .select__timezone,
  .fc-button-group,
  .fc-toolbar-chunk {
    width: 100%;
    max-width: 100%;
  }
  .fc-toolbar-chunk {
    flex: 1 !important;
  }
  .fc-toolbar-title {
    margin: 15px auto !important;
    text-align: center;
  }
  .select__timezone {
    margin-bottom: 5px;
  }
  .fc-today-button {
    margin-left: 0 !important;
    color: white !important;
    padding: 12px 0;
    text-transform: uppercase;
  }
  .fc-dayGridMonth-button,
  .fc-timeGridWeek-button,
  .fc-timeGridDay-button,
  .fc-listweek-button {
    display: none !important;
  }
}

/* button:focus,
button:active,
button:visited,
button:link {
  background: red !important;
} */

.fc-button-group {
  margin-left: 0 !important;
  background: #e94b31 !important;
  border: none;
  width: 100%;
  display: flex;
  border-radius: 8px !important;
  overflow: hidden;
}

.fc-event-main-frame {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .speaker {
    width: 100%;
  }
}

.sticky-warning {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: white;
  z-index: 999;
  left: 0;
  right: 0;
}

.sticky-warning .text {
  max-width: 1000px;
  margin: 0px auto;
  padding: 10px;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .sticky-warning .text {
    font-size: 8px;
    margin: 10px;
    max-width: 800px;
  }
}

.tool-select {
  cursor: pointer;
  border-radius: 8px;
  box-sizing: border-box;
}

.tool-select.purple {
  background: #b093ce !important;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0 !important;
}
.fc.fc-toolbar {
  justify-content: space-between;
  align-items: flex-end !important;
}
.fc-button-group {
  border: none;
  border-radius: 8px !important;
}
.fc-button-group button {
  background: #f18a7b !important;
  flex: 1;
  height: 100%;
  cursor: pointer;
  color: white;
  border: none;
  text-align: center;
}
.fc-button-group button.fc-button-active {
  background: #e94b31 !important;
}

.program-topic-modal {
  display: flex;
  background-color: white;
}

.program-topic-modal:first-child {
  border-top-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
}

.program-topic-1 {
  background: rgba(121, 86, 172, 0.5);
  width: 50%;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: rgb(81, 48, 132);
}
.program-topic-1 div {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
}
.program-topic-2 {
  background: rgba(121, 86, 172, 0.4);
  width: 50%;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: rgb(81, 48, 132);
}
.program-topic-2 div {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
}
.program-desc-1 {
  background-color: rgba(121, 86, 172, 0.3);
  color: rgb(99, 100, 101);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px;
  width: 50%;
}
.program-desc-2 {
  background-color: rgba(121, 86, 172, 0.2);
  color: rgb(99, 100, 101);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px;
  width: 50%;
}
.fc-toolbar-chunk {
  flex: 1 !important;
  margin-top: auto;
}

.fc-toolbar-chunk:last-child {
  display: flex;
  align-items: flex-end;
}
.fc .fc-toolbar-title {
  text-align: center;
  font-size: 1.05em !important;
}

.label-style {
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex: 1 1 auto !important;
  margin: 10px 0;
}
/* .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
  background: green;
} */
.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  justify-content: center;
}

.death-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.fc-toolbar-chunk .fc-button-group {
  order: 4;
  margin-top: 0;
}

.tool-select,
.fc-button-group {
  align-self: center;
  margin-left: 20px !important;
}

.death-wrapper .fc-button-group button {
  height: 35px !important;
  border: none !important;
}

.death-wrapper .label-style span {
  width: 60px;
}

.radius-form input,
.radius-form select {
  border-radius: 12px;
}

.toolbar {
  display: flex;
}
.toolbar section {
  flex: 1;
  padding-inline-end: 0;
  padding-inline-start: 0;
  display: flex;
}

.toolbar .toolbar-form {
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.toolbar select,
.toolbar button,
.toolbar input {
  box-sizing: border-box !important;
  padding: 0 !important;
  height: 35px !important;
  color: white !important;
}

.toolbar #resetButton {
  font-size: 12;
  color: blue !important;
  cursor: pointer;
  background-color: white;
  border: 0;
  text-decoration: underline;
}

.legend {
  font-weight: bold;
  font-size: 10px;
  display: flex;
  position: relative;
  top: 40px;
}

.legend .purple {
  height: 10px;
  width: 10px;

  display: inline-block;
  background: rgb(121, 86, 172);
  border-radius: 200%;
}

.legend .red {
  height: 10px;
  width: 10px;
  background: rgb(239, 60, 77);
  border-radius: 200%;
}
.legend .circle {
  display: inline-block;
  margin-right: 10px;
}
.legend aside:last-child {
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .toolbar,
  .toolbar section,
  label,
  input,
  div,
  span,
  form {
    flex-direction: column !important;
    width: 100% !important;
    text-align: center;
    align-self: center;
    flex: unset !important;
    margin-left: 0 !important;
    align-items: center;
    justify-content: center !important;
  }
  label span {
    display: none;
  }
  .legend span {
    height: 10px !important;
    width: 10px !important;
  }
  form input {
    display: flex;
    margin-bottom: 20px;
  }
  .toolbar section {
    flex: unset;
  }
}

.fc-day-grid-event > .fc-content {
  white-space: normal !important;
}
.fc-event-main-frame {
  white-space: normal;
}
a.fc-daygrid-event {
  display: flex;
  flex-direction: column;
}

.fc-day-today {
  background-color: rgba(121, 86, 172, 0.3) !important;
}
.fc-toolbar-title {
  width: auto !important;
  align-self: center !important;
}

section {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
}

ul {
  margin: 0;
  padding: 0 0 0 1.5em;
}

li {
  margin: 1.5em 0;
  padding: 0;
}

b {
  /* used for event dates/times */
  margin-right: 3px;
}

.flex {
  display: flex;
}
.flex__column {
  flex-direction: column;
}

.fc {
  max-width: 100%;
  width: 100%;
}

.fc-button,
.fc-button:active,
.fc-button:focus,
.fc-button-active,
table tr th {
  outline: none !important;
  border: none !important;
  background: transparent !important;
}

/* .demo-app-main {
  flex-grow: 1;
  padding: 3em;
} */

.fc {
  /* the calendar root */
  max-width: 1100px;
  margin: 0 auto;
}
table {
  background: white;
}

select,
.fc-toolbar-chunk:first-child .fc-button-group,
.fc-toolbar-chunk:last-child .fc-button-group,
.fc-scrollgrid-section-header .fc-col-header,
.fc-today-button {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ef3c4d+0,ac96c7+68,ac96c7+100 */
  background: rgb(239, 60, 77); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef3c4d', endColorstr='#ac96c7',GradientType=0 ); /* IE6-9 */
  background-attachment: fixed;
  border: none;
  border-radius: 4px;
}

.fc-today-button {
  margin-top: 10px !important;
  flex: 1;
}
.fc-v-event {
  background: transparent !important;
}

.fc-daygrid-event,
.fc-timegrid-event i {
  color: black !important;
  font-weight: bold;
  font-size: 12px !important;
}

.fc-timegrid-event.purpleBg div {
  background: rgb(121, 86, 172, 0.2);
  border-radius: 8px;
}

.fc-timegrid-event.redBg div {
  background: rgb(239, 60, 77, 0.2);
  border-radius: 8px;
}

.fc-timegrid-event {
  text-align: center;
  border: none !important;
}

.fc-daygrid-event b,
.fc-timegrid-event b,
.fc-event-time {
  color: rgb(239, 60, 77);
}

.fc-daygrid-block-event {
  display: none !important;
}
table th {
  color: white;
}

.tool-select {
  color: white !important;
}
/* .left {
  margin-left: 20px !important;
} */
.tool-btn button {
  font-size: 10px !important;
}
.select__timezone,
.select__filter_by {
  width: 100%;
  color: white;
  padding: 12px 6px;
  margin-left: 0 !important;
  padding-right: 10px;
}

.select__filter_by option,
.select__timezone option {
  color: black !important;
}

.select__timezone option:selected,
.select__filter_by option:selected {
  color: white !important;
}

.fc-today-button {
  /* display: none !important; */
  border-left: 1px solid white;
}

.fc-event {
  cursor: pointer;
}
.fc-event-main {
  color: black;
}

.fc-popover {
  display: none !important;
}

/* .fc-col-header-cell-cushion {
  opacity: 0;
  transform: scale(0);
  transition: transform .3s cubic(55,0,.1,1);
  will-change: transform;
} */

.isi {
  font-size: 12px;
  margin-top: 10px;
}

.speakers-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.speaker {
  /* color: rgb(239, 60, 77); */
  color: black;
  font-size: 12px;
  margin-bottom: 10px;
  /* width: 45%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.speaker-toggle {
  width: 5%;
  color: rgb(239, 60, 77);
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.speaker-info {
  width: 95%;
}

.speaker-title {
  font-weight: bold;
}

.speaker-bio-min {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  position: relative;
  height: 2.4em;

  overflow: hidden;
  text-overflow: ellipsis;
}

.speaker-bio-max {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  overflow: hidden;
  text-overflow: ellipsis;
}

.scheduled-text {
  margin-top: 5px;
}

.title--black {
  color: black;
  font-weight: bold;
}
.non-bold {
  font-weight: 500 !important;
}
.title--black--underline {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}
.title--purple {
  color: #ac96c7;
  font-weight: bold;
}

.title--red {
  border-bottom: 1px rgb(239, 60, 77) solid;
  font-size: 22px;
  font-weight: bold;
  color: rgb(239, 60, 77);
  padding-bottom: 3px;
  margin-bottom: 3px;
  width: 100%;
}

.references__line {
  margin-bottom: 10px;
}

.border--black {
  border: 1px solid black;
  padding: 10px;
  font-weight: bold;
}
.border--yellow {
  border: 1px solid gold;
  padding: 10px;
}

.table__bullet {
  width: 10px;
  vertical-align: top;
}

.small_margin_bottom {
  margin-bottom: 5px;
}

.footer-section {
  width: 100%;
  margin: 10px auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.footer-logo-text {
  margin-bottom: 15px;
  margin-top: 10px;
}

.footer-links {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  .footer-links {
    text-align: center;
    flex-direction: column;
  }
}
.death {
  display: flex;
  justify-content: space-around;
}
.death div {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal__event--active {
  border: 2px solid black;
}
.modal__section {
  padding: 0 10px;
  margin-bottom: 50px;
}
.fc-daygrid-event-dot {
  border-radius: 200% !important;
  /* height: 10px;
  width: 10px; */
  border-color: #b093ce !important;
}
.modal__event {
  border-radius: 6px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
  display: flex;
  flex-direction: column;
  background-color: white;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,7db9e8+100&1+0,0+100 */
  /*background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(125, 185, 232, 0) 100%
  ); /* FF3.6-15 */
  /*background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(125, 185, 232, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  /*background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#007db9e8',GradientType=1 ); IE6-9 */
}
.bio {
  text-align: left;
  padding: 10px;
}
.modal__content {
  background: rgb(121, 86, 172);
  box-sizing: border-box;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 11px;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.modal__content h1 {
  margin-bottom: 16px;
}
.modal__button {
  padding: 2px 30px;
  border: none;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  background: #ef3c4d;
  color: white;
  border-radius: 50px;
  align-self: center;
  margin-left: 10px;
  margin-top: 10px;
  text-decoration: none;
}
.modal__time {
  color: #ef3c4d;
}
.modal__doctor {
  margin-left: 10px;
}
.modal__doctor,
.modal__time {
  font-weight: bold !important;
}

.fc-toolbar-chunk {
  display: flex;
  flex-wrap: wrap;
}
.fc-toolbar-chunk:nth-child(1) {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  vertical-align: bottom;
}
.fc-today-button {
  color: white !important;
}
.fc-event-title {
  color: black;
}
.fc-list-table tr th {
  background: rgb(239, 60, 77); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(239, 60, 77, 1) 0%,
    rgba(172, 150, 199, 1) 68%,
    rgba(172, 150, 199, 1) 100%
  ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ef3c4d', endColorstr='#ac96c7',GradientType=0 ); /* IE6-9 */
  background-attachment: fixed;
  border: none;
}

@media screen and (max-width: 768px) {
  .fc-header-toolbar {
    display: flex;
    flex-direction: column;
  }
  .death {
    flex-direction: column;
  }
  .modal__content {
    width: 100% !important;
    transform: none !important;
    display: flex;
    flex-direction: column;
  }

  .modal__event {
    padding: 20px;
    margin: 0 10px 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .modal__button {
    margin: 20px auto;
  }
  .select__timezone,
  .fc-button-group,
  .fc-toolbar-chunk {
    width: 100%;
    max-width: 100%;
  }
  .fc-toolbar-chunk {
    flex: 1 !important;
  }
  .fc-toolbar-title {
    margin: 15px auto !important;
    text-align: center;
  }
  .select__timezone {
    margin-bottom: 5px;
  }
  .fc-today-button {
    margin-left: 0 !important;
    color: white !important;
    padding: 12px 0;
    text-transform: uppercase;
  }
  .fc-dayGridMonth-button,
  .fc-timeGridWeek-button,
  .fc-timeGridDay-button,
  .fc-listweek-button {
    display: none !important;
  }
}

/* button:focus,
button:active,
button:visited,
button:link {
  background: red !important;
} */

.fc-button-group {
  margin-left: 0 !important;
  background: #e94b31 !important;
  border: none;
  width: 100%;
  display: flex;
  border-radius: 8px !important;
  overflow: hidden;
}

.fc-event-main-frame {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .speaker {
    width: 100%;
  }
}

.sticky-warning {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: white;
  z-index: 999;
  left: 0;
  right: 0;
}

.sticky-warning .text {
  max-width: 1000px;
  margin: 0px auto;
  padding: 10px;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .sticky-warning .text {
    font-size: 8px;
    margin: 10px;
    max-width: 800px;
  }
}

.tool-select {
  cursor: pointer;
  border-radius: 8px;
  box-sizing: border-box;
}

.tool-select.purple {
  background: #b093ce !important;
}
.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0 !important;
}
.fc.fc-toolbar {
  justify-content: space-between;
  align-items: flex-end !important;
}
.fc-button-group {
  border: none;
  border-radius: 8px !important;
}
.fc-button-group button {
  background: #f18a7b !important;
  flex: 1;
  height: 100%;
  cursor: pointer;
  color: white;
  border: none;
  text-align: center;
}
.fc-button-group button.fc-button-active {
  background: #e94b31 !important;
}

.program-topic-modal {
  display: flex;
  background-color: white;
}

.program-topic-modal:first-child {
  border-top-left-radius: 1.5em;
  border-top-right-radius: 1.5em;
}

.program-topic-1 {
  background: rgba(121, 86, 172, 0.5);
  width: 50%;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: rgb(81, 48, 132);
}
.program-topic-1 div {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
}
.program-topic-2 {
  background: rgba(121, 86, 172, 0.4);
  width: 50%;
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: rgb(81, 48, 132);
}
.program-topic-2 div {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-left: 10px;
}
.program-desc-1 {
  background-color: rgba(121, 86, 172, 0.3);
  color: rgb(99, 100, 101);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px;
  width: 50%;
}
.program-desc-2 {
  background-color: rgba(121, 86, 172, 0.2);
  color: rgb(99, 100, 101);
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px;
  width: 50%;
}
.fc-toolbar-chunk {
  flex: 1 !important;
  margin-top: auto;
}

.fc-toolbar-chunk:last-child {
  display: flex;
  align-items: flex-end;
}
.fc .fc-toolbar-title {
  text-align: center;
  font-size: 1.05em !important;
}

.label-style {
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex: 1 1 auto !important;
  margin: 10px 0;
}
/* .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
  background: green;
} */
/* .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  flex-grow: 0.5;
} */

.death-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.fc-toolbar-chunk .fc-button-group {
  order: 4;
  margin-top: 0;
}

.tool-select,
.fc-button-group {
  align-self: center;
  margin-left: 20px !important;
}

.death-wrapper .fc-button-group button {
  height: 35px !important;
  border: none !important;
}

.death-wrapper .label-style span {
  width: 60px;
}

.radius-form input,
.radius-form select {
  border-radius: 12px;
}

.toolbar {
  display: flex;
}
.toolbar section {
  flex: 1;
  padding-inline-end: 0;
  padding-inline-start: 0;
  display: flex;
}

.toolbar .toolbar-form {
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
}

.toolbar select,
.toolbar button,
.toolbar input {
  box-sizing: border-box !important;
  padding: 0 !important;
  height: 35px !important;
  color: white !important;
}

.legend {
  font-weight: bold;
  font-size: 10px;
  display: flex;
  position: relative;
  top: 40px;
}

.legend .purple {
  height: 10px;
  width: 10px;

  display: inline-block;
  background: rgb(121, 86, 172);
  border-radius: 200%;
}

.legend .red {
  height: 10px;
  width: 10px;
  background: rgb(239, 60, 77);
  border-radius: 200%;
}
.legend .circle {
  display: inline-block;
  margin-right: 10px;
}
.legend aside:last-child {
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .toolbar,
  .toolbar section,
  label,
  input,
  div,
  span,
  form {
    flex-direction: column !important;
    width: 100% !important;
    text-align: center;
    align-self: center;
    flex: unset !important;
    margin-left: 0 !important;
    align-items: center;
    justify-content: center !important;
  }
  label span {
    display: none;
  }
  .legend span {
    height: 10px !important;
    width: 10px !important;
  }
  form input {
    display: flex;
    margin-bottom: 20px;
  }
  .toolbar section {
    flex: unset;
  }
}
.fc-day-grid-event > .fc-content {
  white-space: normal !important;
}
.fc-event-main-frame {
  white-space: normal;
}
a.fc-daygrid-event {
  display: flex;
  flex-direction: column;
}

.fc-day-today {
  background-color: rgba(121, 86, 172, 0.3) !important;
}
.fc-toolbar-title {
  width: auto !important;
  align-self: center !important;
}

.shadow-up {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
#map {
  transition: 0.3s height cubic-bezier(55, 0, 0.1, 1);
  will-change: transform;
}

.shadow-up {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
#map {
  transition: 0.3s height cubic-bezier(55, 0, 0.1, 1);
  will-change: transform;
}
@media screen and (max-width: 768px) {
  .fc-header-title {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }
  .fc-toolbar-chunk {
    flex-direction: row !important;
  }
  .fc-toolbar-title {
    flex: auto;
    margin-top: 200px;
  }
  .legend {
    display: none;
  }
  input[type='submit'] {
    margin-top: 20px;
  }
  .toolbar-form span {
    text-align: center !important;
  }
}
a.fc-daygrid-event {
  display: flex;
  flex-direction: row !important;
  font-size: 10px !important;
}
.fc-daygrid-dot-event span,
.fc-timegrid-event .fc-event-main span {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 200%;
  margin-right: 3px;
}
.fc-dayGridMonth-button {
  background: #f18a7b !important;
}

#zipcode {
  padding-left: 5px;
  color: black !important;
}
